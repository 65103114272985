<template>
  <div>
    <div v-if="deleteAssistent" class="flix-alert flix-alert-success">
      {{ $t('message.successfullDeleted') }}
    </div>
    <div v-else>
      <h1 class="flix-html-h1">{{ assistent.title }}</h1>
      <div class="flix-form-group" v-if="bookings">
        <div class="flix-alert flix-alert-warning">
          {{ $t('message.bookingsWillBeDeleted', { count: bookings }) }}
        </div>
      </div>
      <form
        @submit.prevent="
          () => {
            if (entry === check) {
              setDelete()
            }
          }
        "
      >
        <div class="flix-form-group">
          <label class="flix-html-label" for="deletion"
            >{{ $t('message.verifyDeletion') }}
            <span class="flix-text-danger">{{ check }}</span></label
          >
          <input class="flix-form-control" id="deletion" v-model="entry" />
        </div>
        <div class="flix-form-group">
          <button
            type="submit"
            :class="{ 'flix-disabled': entry != check }"
            class="flix-btn flix-btn-danger flix-btn-block"
          >
            {{ $t('message.delete') }}
          </button>
        </div>
        <div class="flix-form-group">
          <b-flix-alert variant="danger">{{
            $t('message.backupHint')
          }}</b-flix-alert>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      assistent: false,
      bookings: false,
      key: false,
      check: false,
      entry: '',
      deleteAssistent: true
    }
  },
  methods: {
    setDelete() {
      this.$flix_post({
        url: 'assistent/delete',
        data: {
          user: this.$store.getters.user.md5_id,
          ID: this.assistent.ID
        },
        callback: function (ret) {
          var a = this.$store.getters.assistents
          a.splice(this.key, 1)
          this.$store.commit('setAssistents', a)
          this.deleteAssistent = true
          this.$store.commit('setActiveAssistent', false)
        }.bind(this)
      })
    },
    getCalendar() {
      var a = this.$store.getters.assistents
      Object.keys(a).forEach(
        function (key) {
          a[key].ID = a[key].ID + ''
          if (a[key].ID === this.$route.params.id + '') {
            this.assistent = a[key]
            this.deleteAssistent = false
            if (
              typeof this.$store.getters.bookings[a[key].ID] !== 'undefined'
            ) {
              this.bookings = this.$store.getters.bookings[a[key].ID].length
            }
            this.check =
              a[key].ID[a[key].ID.length - 1] +
              a[key].ID[a[key].ID.length - 2] +
              a[key].ID[a[key].ID.length - 3] +
              a[key].ID[a[key].ID.length - 4]
            this.key = key
          }
        }.bind(this)
      )
    }
  },
  mounted() {
    this.getCalendar()
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
